import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import UserIcons from "../assets/images/Home/user-icons.png";
import VideoBanner from "../assets/images/Home/video-banner.png";
import PlayIcon from "../assets/images/Home/play-icon.png";
import ReactPlayer from "react-player";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const HomePage = () => {
  const [loading, setLoading] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const [waitLoading, setWaitLoading] = useState(false);

  const handlePlaying = async () => {
    setPlaying(true);
  };

  const joinWaitList = async (e) => {
    try {
      e.preventDefault();
      setWaitLoading(true);

      const result = await axios.post(
        "http://api.votingapp.trangotechdevs.com:3023/api/user/waitlist",
        {
          email: userEmail,
        },
        {
          headers: {
            token: "180528e2-1695-4fad-a57e-2bda38951d0a",
          },
        }
      );

      const { code, message } = result.data;
      if (code === 200) {
        toast.success(message);
      }

      setWaitLoading(false);
      setUserEmail("");
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      setWaitLoading(false);
    }
  };

  return (
    <div className="home-page">
      <Toaster />
      <Container>
        <Row>
          <Col xs={12} lg={6} className="mb-5">
            <div>
              <div className="top-items">
                <h2>Drive Change </h2>
                <h1>
                  Together, Empower <br />
                  Your Voice Today
                </h1>
                <div className="small-desc">
                  Join Kansensus To Amplify Your Voice And Drive Real Global
                  Change, Unite <br /> With Others To Make A Meaningful Impact
                </div>
              </div>

              <div className="mt-3">
                <div className="sign-up">
                  Sign Up for the waitlist now and get 100 Points
                </div>
                <form onSubmit={joinWaitList}>
                  <div className="input-btn">
                    <input
                      type="email"
                      value={userEmail}
                      onChange={(e) => setUserEmail(e.target.value)}
                      placeholder="Enter Email"
                      required
                    />
                    {waitLoading ? (
                      <div className="spinner-border text-dark" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <button type="submit">Join Waitlist</button>
                    )}
                  </div>
                </form>
              </div>

              <div className="user-count-box">
                <div>
                  <img src={UserIcons} alt="" className="user-images" />
                </div>
                <div>
                  <div className="user-counts">430,000 Users</div>
                  <div className="user-desc">
                    Is Building Kansensus Together
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            {playing ? (
              <div className="video-box">
                <ReactPlayer
                  className="react-player"
                  url="https://votingapp.trangotechdevs.com/voting_video.mp4"
                  playing={playing}
                  controls={true}
                  // width={"550px"}
                  // height={"360px"}
                />
              </div>
            ) : (
              <div className="video-box">
                <div className="video-main">
                  <div
                    className="video-image-play-icon"
                    onClick={handlePlaying}
                  >
                    <img src={VideoBanner} alt="" className="video-image" />

                    <div className="play-icon">
                      <img src={PlayIcon} alt="" className="play-icon-img" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export { HomePage };
