import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../assets/images/Home/TopHeader/logo.png";

const TopNavBanner = ({ className }) => {
  let location = window.location;
  let pathname = location.pathname;

  const [expanded, setExpanded] = useState(false);

  const handleNavToggle = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <div className="container">
    <div className={` top-nav-bar ${className}`}>
      <div className="outer-box">
        <div>
          <img src={Logo} alt="" className="image" />
        </div>
        <div>
          <h2>Kansensus</h2>
        </div>
      </div>

      {/* <Navbar bg="" expand="lg" variant="dark">
        <Container className="d-flex justify-content-center">
          <img src={Logo} alt="produtc-logo" />
          <Navbar.Brand href="/"></Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={handleNavToggle}
          />
          {/* <Navbar.Collapse id="responsive-navbar-nav">
              <Nav
                className="ms-auto"
                defaultActiveKey="/"
                onClick={handleNavToggle}
              >
                <Nav.Item className={"p-3"}>
                  <Link
                    to="/"
                    onClick={handleNavToggle}
                    className={activeLink("/") ? "active" : ""}
                  >
                    Home
                  </Link>
                </Nav.Item>
                <Nav.Item className={"p-3"}>
                  <Link
                    to="/"
                    // to={`${location.origin}#feature`}
                    onClick={handleNavToggle}
                    className={activeLink("#feature") ? "active" : ""}
                  >
                    Features
                  </Link>
                </Nav.Item>
                <Nav.Item className={"p-3"}>
                  <Link
                    // to="/about-us"
                    to="/"
                    onClick={handleNavToggle}
                    className={activeLink("/about-us") ? "active" : ""}
                  >
                    About us
                  </Link>
                </Nav.Item>
                <Nav.Item className={"p-3"}>
                  <Link
                    // to={`${location.origin}#reviews`}
                    to="/"
                    onClick={handleNavToggle}
                    className={activeLink("#reviews") ? "active" : ""}
                  >
                    Reviews
                  </Link>
                </Nav.Item>

                <Button
                  // onClick={() => navigate(`/contact-us`)}
                  onClick={() => navigate(`/`)}
                  className="btn-theme-default btn-contact-us"
                >
                  contact Us
                </Button>
              </Nav>
            </Navbar.Collapse> 
        </Container>
      </Navbar> */}
    </div>
    </div>

  );
};

export default TopNavBanner;
